import { Flex, Text } from '@dione/uikit'
import styled from 'styled-components'
import { useTranslation } from '@dione/localization'
import { ProposalState } from 'state/types'

interface FiltersProps {
  filterState: ProposalState
  onFilterChange: (filterState: ProposalState) => void
  isLoading: boolean
}

const StyledFilters = styled(Flex)`
  padding: 12px 12px;
  flex-direction: column;
  align-items: stretch;
  min-width: 68px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 16px 48px 16px 0;
  }
`
const StyledFiltersInner = styled(Flex).attrs({ alignItems: 'center' })`
  gap: 8px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt10};
  box-shadow: ${({ theme }) => theme.shadows.card};
  border-radius: 500px;
`

const FilterLabel = styled.button<{ isActive?: boolean; valueID?: string }>`
  align-items: center;
  cursor: pointer;
  border-radius: 500px;
  display: flex;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.backgroundAlt10 : 'transparent')};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.text : theme.colors.textSubtle)};
  box-shadow: ${({ isActive, theme }) => (isActive ? theme.shadows.innerCard : 'none')};
  border: none;
  padding: 4px 8px;
  line-height: 20px;
  flex-grow: 1;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-grow: 0;
  }
`

const options = [
  { value: ProposalState.ACTIVE, label: 'Vote Now' },
  { value: ProposalState.PENDING, label: 'Soon' },
  { value: ProposalState.CLOSED, label: 'Closed' },
]

const Filters: React.FC<React.PropsWithChildren<FiltersProps>> = ({ filterState, onFilterChange, isLoading }) => {
  const { t } = useTranslation()

  return (
    <StyledFilters>
      <StyledFiltersInner>
        {options.map(({ value, label }) => {
          const handleChange = (radioValue?: string) => {
            onFilterChange(radioValue as ProposalState)
          }

          return (
            <FilterLabel
              key={label}
              valueID={value}
              isActive={filterState === value}
              onClick={handleChange.bind(this, value)}
              disabled={isLoading}
            >
              <Text fontWeight="500" fontSize="14px" color="currentColor">
                {t(label)}
              </Text>
            </FilterLabel>
          )
        })}
      </StyledFiltersInner>
    </StyledFilters>
  )
}

export default Filters
