import { Box, Button, Flex, Heading, Text } from '@dione/uikit'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from '@dione/localization'
import Container from 'components/Layout/Container'
import Link from 'next/link'
import { ChevronRight } from 'react-iconly'

const StyledHero = styled(Box)`
  padding-bottom: 24px;
  padding-top: 68px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 40px;
    padding-top: 100px;
  }
`

const Hero = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <StyledHero>
      <Container>
        <Flex alignItems="center" justifyContent="space-between" flexDirection="column">
          <Heading as="h1" fontWeight="500 !important" scale="xl" color="text" mb="8px" textAlign="center">
            {t('Voting')}
          </Heading>
          <Text as="h3" fontWeight="500" fontSize="14px" mb="24px" color="textSubtle" textAlign="center">
            {t('Have your say in the future of the Dione Protocol Ecosystem')}
          </Text>
          <Link href="/governance/proposal/create" passHref prefetch={false}>
            <Button
              variant="iconic"
              iconicElement={<ChevronRight size={12} set="light" primaryColor={theme.colors.text} />}
            >
              {t('Make a Proposal')}
            </Button>
          </Link>
        </Flex>
      </Container>
    </StyledHero>
  )
}

export default Hero
