import { ProposalRPC, ProposalTimeEnhanced, Proposal, ExecutorRPC } from 'hooks/contracts/governance/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import md5 from 'md5'
import { enhanceProposalWithTimes, humanizeProposal } from 'hooks/contracts/governance/helpers'
import { usePublicClient } from 'wagmi'
import { ProposalMetadata } from 'utils/ipfs/types'
import { getProposalMetadata } from 'utils/ipfs'
import { governanceConfig } from 'views/Governance/config'

export function useFormattedProposals(
  proposals: ProposalRPC[],
  states: bigint[],
): [ProposalTimeEnhanced[], ProposalMetadata[]] {
  const [enhancedProposals, setEnhancedProposals] = useState<ProposalTimeEnhanced[]>()
  const [ipfsMetadatas, setIpfsMetadatas] = useState<ProposalMetadata[]>()
  const provider = usePublicClient()

  const updateTheProposals = useCallback(
    async (_proposals, _states) => {
      const promises: Promise<ProposalTimeEnhanced>[] = _proposals.map(async (item: ProposalRPC, i) => {
        if (!item || !item?.creator) return {} as ProposalTimeEnhanced

        const proposal: Proposal = humanizeProposal(item, _states[i])
        return enhanceProposalWithTimes(proposal, provider)
      })

      const transformedProposals: ProposalTimeEnhanced[] = await Promise.all(promises)

      setEnhancedProposals(transformedProposals)
    },
    [provider, setEnhancedProposals],
  )

  const fetchMetadatas = useCallback(
    async (_proposals) => {
      const promises: Promise<ProposalMetadata>[] = _proposals.map(async (item: ProposalRPC) => {
        if (!item?.ipfsHash) return {} as ProposalMetadata

        return getProposalMetadata(item?.ipfsHash, governanceConfig.ipfsGateway)
      })

      const ipfsRes: ProposalMetadata[] = await Promise.all(promises)

      setIpfsMetadatas(ipfsRes)
    },
    [setIpfsMetadatas],
  )

  useEffect(() => {
    updateTheProposals(proposals, states)

    fetchMetadatas(proposals)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposals])

  return useMemo(() => {
    return [enhancedProposals, ipfsMetadatas]
  }, [ipfsMetadatas, enhancedProposals])
}

export function useFormattedProposal(
  proposal: ProposalRPC,
  state: bigint,
  executorSettings: ExecutorRPC,
): [ProposalTimeEnhanced, ProposalMetadata] {
  const [enhancedProposal, setEnhancedProposal] = useState<ProposalTimeEnhanced>()
  const [ipfsMetadata, setIpfsMetadata] = useState<ProposalMetadata>()
  const provider = usePublicClient()

  const updateTheProposal = useCallback(
    async (_proposal, _state) => {
      if (!_proposal) return

      const hProposal: Proposal = humanizeProposal(_proposal, _state, executorSettings)
      const transformedProposal: ProposalTimeEnhanced = await enhanceProposalWithTimes(hProposal, provider)

      setEnhancedProposal(transformedProposal)
    },
    [provider, setEnhancedProposal, executorSettings],
  )

  const fetchMetadata = useCallback(
    async (_proposal) => {
      if (!_proposal?.ipfsHash) return

      const ipfsRes: ProposalMetadata = await getProposalMetadata(_proposal?.ipfsHash, governanceConfig.ipfsGateway)
      setIpfsMetadata(ipfsRes)
    },
    [setIpfsMetadata],
  )

  useEffect(() => {
    updateTheProposal(proposal, state)

    fetchMetadata(proposal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal?.creator])

  return useMemo(() => {
    return [enhancedProposal, ipfsMetadata]
  }, [ipfsMetadata, enhancedProposal])
}
