export type tEthereumAddress = string
export declare type Bytes = ArrayLike<number>
export declare type BytesLike = Bytes | string

export enum ExecutorType {
  Short,
  Long,
}

export enum ProposalType {
  ALL = 'all',
  CORE = 'core',
  COMMUNITY = 'community',
}

export enum ProposalState {
  Pending = 'Pending',
  Canceled = 'Canceled',
  Active = 'Active',
  Failed = 'Failed',
  Succeeded = 'Succeeded',
  Queued = 'Queued',
  Expired = 'Expired',
  Executed = 'Executed',
}

export type GovCreateType = {
  user: tEthereumAddress
  targets: tEthereumAddress[]
  values: string[]
  signatures: string[]
  calldatas: BytesLike[]
  withDelegateCalls: boolean[]
  ipfsHash: BytesLike
  executor: ExecutorType
}
export type GovCancelType = {
  user: tEthereumAddress
  proposalId: number
}
export type GovQueueType = {
  user: tEthereumAddress
  proposalId: number
}
export type GovExecuteType = {
  user: tEthereumAddress
  proposalId: number
}

export type GovSubmitVoteType = {
  user: tEthereumAddress
  proposalId: number
  support: boolean
}

export type GovSubmitVoteSignType = {
  user: tEthereumAddress
  proposalId: number
  support: boolean
  signature: string
}

export type ProposalIpfs = {
  id: number
  title: string
  description: string
  shortDescription: string
  creator: tEthereumAddress
  executor: tEthereumAddress
  targets: tEthereumAddress[]
  values: string[]
  signatures: string[]
  calldatas: string[]
  withDelegatecalls: boolean[]
  startBlock: number
  endBlock: number
  executionTime: string
  executionTimeWithGracePeriod: string
  forVotes: string
  againstVotes: string
  executed: boolean
  canceled: boolean
  strategy: string
  ipfsHash: string
  state: ProposalState
  minimumQuorum: string
  minimumDiff: string
  proposalCreated: number
}

export type Proposal = {
  id: number
  creator: tEthereumAddress
  executor: tEthereumAddress
  targets: tEthereumAddress[]
  values: bigint[]
  signatures: string[]
  calldatas: string[]
  withDelegatecalls: boolean[]
  startBlock: number
  endBlock: number
  executionTime: number
  executionTimeWithGracePeriod: number
  forVotes: string
  againstVotes: string
  executed: boolean
  canceled: boolean
  strategy: string
  ipfsHash: string
  state: ProposalState
  minimumQuorum: string
  minimumDiff: string
  proposalCreated: number
}

export type ProposalTimeEnhanced = Proposal & {
  startTimestamp: bigint
  expirationTimestamp: bigint
}

export type ProposalRPC = {
  minimumQuorum: bigint
  minimumDiff: bigint
  executionTimeWithGracePeriod: bigint
  proposalCreated: bigint
  id: bigint
  creator: string
  executor: string
  targets: string[]
  values: bigint[]
  signatures: string[]
  calldatas: string[]
  withDelegatecalls: boolean[]
  startBlock: bigint
  endBlock: bigint
  executionTime: bigint
  forVotes: bigint
  againstVotes: bigint
  executed: boolean
  canceled: boolean
  strategy: string
  ipfsHash: string
  proposalState: number
}

export type FormattedProposal = {
  id?: string
  totalVotes: number
  yaePercent: number
  yaeVotes: number
  nayPercent: number
  nayVotes: number
  minQuorumVotes: number
  quorumReached: boolean
  diff: number
  requiredDiff: number
  diffReached: boolean
}

export type ExecutorRPC = {
  PROPOSITION_THRESHOLD: bigint
  VOTING_DURATION: bigint
  VOTE_DIFFERENTIAL: bigint
  MINIMUM_QUORUM: bigint
  GRACE_PERIOD: bigint
  MINIMUM_DELAY: bigint
  MAXIMUM_DELAY: bigint
  getDelay: bigint
}

export type Power = {
  votingPower: bigint
  propositionPower: bigint
  0: bigint
  1: bigint
}

export type Vote = {
  voter: string
  support: boolean
  votingPower: bigint
  0: string
  1: boolean
  2: bigint
}
