import { Card, Flex, Text } from '@dione/uikit'
import styled from 'styled-components'
import { useTranslation } from '@dione/localization'
import Container from 'components/Layout/Container'
import { ProposalState, ProposalType } from 'state/types'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { useFormattedProposals } from 'hooks/contracts/governance/utils'
import { useProposals } from 'hooks/contracts'

import { filterProposalsByState, filterProposalsByType } from '../../helpers'
import ProposalsLoading from './ProposalsLoading'
import TabMenu from './TabMenu'
import ProposalRow from './ProposalRow'
import Filters from './Filters'

const ProposalsContainer = styled(Flex)`
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  padding: 8px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px 0;
  }
`

interface State {
  proposalType: ProposalType
  filterState: ProposalState
}

const Proposals = () => {
  const { t } = useTranslation()
  const [state, setState] = useSessionStorage<State>('proposals-filter', {
    proposalType: ProposalType.CORE,
    filterState: ProposalState.ACTIVE,
  })

  const { proposalType, filterState } = state

  const [isLoadingProposals, isErrorProposals, proposals, states] = useProposals()

  const [data, proposalsIpfs] = useFormattedProposals(proposals, states)

  const handleProposalTypeChange = (newProposalType: ProposalType) => {
    setState((prevState) => ({
      ...prevState,
      proposalType: newProposalType,
    }))
  }

  const handleFilterChange = (newFilterState: ProposalState) => {
    setState((prevState) => ({
      ...prevState,
      filterState: newFilterState,
    }))
  }

  const [filteredProposals, filteredIpfsMetadata] = filterProposalsByState(
    ...filterProposalsByType(data, proposalsIpfs, proposalType),
    filterState,
  )

  return (
    <Container py="40px">
      <Text as="h2" fontSize="28px" fontWeight="500" mb="24px" id="voting-proposals">
        {t('Proposals')}
      </Text>
      <Card>
        <TabMenu
          proposalType={proposalType}
          onTypeChange={handleProposalTypeChange}
          rightContent={
            <Filters filterState={filterState} onFilterChange={handleFilterChange} isLoading={isLoadingProposals} />
          }
        />
        {isLoadingProposals && <ProposalsLoading />}
        {!isLoadingProposals && !isErrorProposals && filteredProposals.length > 0 && (
          <ProposalsContainer>
            {filteredProposals.map((proposal, i) => {
              return <ProposalRow key={proposal.id} proposal={proposal} ipfs={filteredIpfsMetadata[i]} />
            })}
          </ProposalsContainer>
        )}
        {(isErrorProposals || filteredProposals.length === 0) && (
          <Flex alignItems="center" justifyContent="center" p="64px 32px">
            <Text fontSize="16px" as="h5" fontWeight="500" color="textSubtle">
              {t('No proposals found')}
            </Text>
          </Flex>
        )}
      </Card>
    </Container>
  )
}

export default Proposals
