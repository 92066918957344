import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M8.33203 13.3333L11.6654 9.99996L8.33203 6.66663"
        strokeLinejoin="round"
        fill="none"
        strokeWidth="1"
        stroke="currentColor"
      />
    </Svg>
  );
};

export default Icon;
