import { formatUnits, PublicClient } from 'viem'
import {
  Proposal,
  FormattedProposal,
  ProposalRPC,
  ProposalState,
  tEthereumAddress,
  ExecutorRPC,
  ProposalTimeEnhanced,
} from './types'

const averageBlockTime = 3
export function formatProposal(proposal: Omit<Proposal, 'values'>, totalVotingSupply: bigint): FormattedProposal {
  const allVotes = BigInt(proposal.forVotes) + BigInt(proposal.againstVotes)
  const yaePercent = allVotes > 0n ? Number((BigInt(proposal.forVotes) * 100n) / allVotes) : 0
  const yaeVotes = Number(formatUnits(BigInt(proposal.forVotes), 18))
  const nayPercent = allVotes > 0n ? Number((BigInt(proposal.againstVotes) * 100n) / allVotes) : 0
  const nayVotes = Number(formatUnits(BigInt(proposal.againstVotes), 18))

  const minQuorumVotes = totalVotingSupply * (BigInt(proposal.minimumQuorum) / 10000n)
  let quorumReached = false
  if (BigInt(proposal.forVotes) >= minQuorumVotes) {
    quorumReached = true
  }

  const diff = BigInt(proposal.forVotes) - BigInt(proposal.againstVotes)
  const voteSum = allVotes

  const requiredDiff = (totalVotingSupply * BigInt(proposal.minimumDiff)) / 10000n

  // Differential reached if difference between yea and nay votes exceeds min threshold, and proposal has at least one voter
  const diffReached = requiredDiff <= diff && voteSum !== 0n

  return {
    totalVotes: Number(formatUnits(allVotes, 18)),
    yaePercent,
    yaeVotes,
    nayPercent,
    nayVotes,
    minQuorumVotes: Number(formatUnits(minQuorumVotes, 18)),
    quorumReached,
    diff: Number(formatUnits(diff, 18)),
    requiredDiff: Number(formatUnits(requiredDiff, 18)),
    diffReached,
  }
}

export async function enhanceProposalWithTimes(
  proposal: Omit<Proposal, 'values'>,
  provider: PublicClient,
): Promise<ProposalTimeEnhanced> {
  if (proposal.state === ProposalState.Pending) {
    const currentBlock = await provider.getBlock()
    return {
      ...proposal,
      startTimestamp:
        currentBlock.timestamp + BigInt((proposal.startBlock - Number(currentBlock.number)) * averageBlockTime),
      expirationTimestamp:
        currentBlock.timestamp + BigInt((proposal.endBlock - Number(currentBlock.number)) * averageBlockTime),
    } as ProposalTimeEnhanced
  }

  const { timestamp: startTimestamp } = await provider.getBlock({
    blockNumber: BigInt(proposal.startBlock),
  })
  if (proposal.state === ProposalState.Active) {
    const currentBlock = await provider.getBlock()
    return {
      ...proposal,
      startTimestamp,
      expirationTimestamp:
        currentBlock.timestamp + BigInt((proposal.endBlock - Number(currentBlock.number)) * averageBlockTime),
    } as ProposalTimeEnhanced
  }
  const expirationTimestamp = startTimestamp + BigInt((proposal.endBlock - proposal.startBlock) * averageBlockTime)
  return { ...proposal, startTimestamp, expirationTimestamp } as ProposalTimeEnhanced
}

export const humanizeProposal = (rawProposal: ProposalRPC, state: bigint, rawExecutor?: ExecutorRPC): Proposal => {
  return {
    id: Number(rawProposal.id),
    creator: rawProposal.creator as tEthereumAddress,
    executor: rawProposal.executor as tEthereumAddress,
    targets: rawProposal.targets.map((t) => t as tEthereumAddress),
    values: rawProposal.values,
    signatures: rawProposal.signatures,
    calldatas: rawProposal.calldatas,
    withDelegatecalls: rawProposal.withDelegatecalls,
    startBlock: Number(rawProposal.startBlock),
    endBlock: Number(rawProposal.endBlock),
    executionTime: Number(rawProposal.executionTime),
    forVotes: rawProposal.forVotes.toString(),
    againstVotes: rawProposal.againstVotes.toString(),
    executed: rawProposal.executed,
    canceled: rawProposal.canceled,
    strategy: rawProposal.strategy,
    state: Object.values(ProposalState)[Number(state)] as ProposalState,
    minimumQuorum: (rawExecutor?.MINIMUM_QUORUM || 0n).toString(),
    minimumDiff: (rawExecutor?.VOTE_DIFFERENTIAL || 0n).toString(),
    executionTimeWithGracePeriod: Number(rawProposal.executionTime + (rawExecutor?.GRACE_PERIOD || 0n)),
    proposalCreated: Number(rawProposal.startBlock),
    ipfsHash: rawProposal.ipfsHash,
  }
}
