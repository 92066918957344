import { Box, Button, Text, Flex } from '@dione/uikit'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from '@dione/localization'
import Link from 'next/link'
import Container from 'components/Layout/Container'
import { ChevronRight } from 'react-iconly'

const StyledFooter = styled(Box)`
  padding-bottom: 48px;
  padding-top: 16px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 110px;
    padding-top: 80px;
  }

  &::before {
    content: '';
    width: 361.293px;
    height: 428.145px;
    transform: rotate(-75deg);
    flex-shrink: 0;
    position: absolute;
    left: 0;
    top: 10%;
    background-color: ${({ theme }) => theme.colors.bgBlue};
    opacity: 0.3;
    filter: blur(130px);
    pointer-events: none !important;
    z-index: 2;
  }

  &::after {
    content: '';
    width: 190.304px;
    height: 276.954px;
    transform: rotate(-13.539deg);
    position: absolute;
    right: 0;
    bottom: 10%;
    background-color: ${({ theme }) => theme.colors.secondary};
    opacity: 0.3;
    filter: blur(185px);
    pointer-events: none !important;
    z-index: 2;
  }
`

const InnerContainer = styled(Flex)`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt3};
  border: 1px solid ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 24px;
  text-align: left;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 40px 64px;
    text-align: center;
  }
`

const Footer = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <StyledFooter>
      <Container>
        <InnerContainer alignItems={['stretch', null, 'center']} justifyContent="space-between" flexDirection="column">
          <Text as="h2" fontSize={['18px', null, '28px']} mb={['12px', null, '24px']} fontWeight="600">
            {t('Got a suggestion?')}
          </Text>
          <Text as="p" fontSize={['12px', null, '16px']} fontWeight="500" color="textSubtle">
            {t('Community proposals are a great way to see how the community feels about your ideas.')}
          </Text>
          <Text
            as="p"
            fontSize={['12px', null, '16px']}
            fontWeight="500"
            color="textSubtle"
            mb={['16px', null, '24px']}
          >
            {t(
              "They won't necessarily be implemented if the community votes successful, but suggestions with a lot of community support may be made into Core proposals.",
            )}
          </Text>

          <Link href="/governance/proposal/create" passHref prefetch={false}>
            <Button
              variant="iconic"
              iconicElement={<ChevronRight size={12} set="light" primaryColor={theme.colors.text} />}
            >
              {t('Make a Proposal')}
            </Button>
          </Link>
        </InnerContainer>
      </Container>
    </StyledFooter>
  )
}

export default Footer
