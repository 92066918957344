import styled from "styled-components";
import { color } from "styled-system";
import { TabProps } from "./types";

const Tab = styled.button<TabProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-width: 0 0 4px 0;
  outline: 0;
  flex-grow: 1;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  border-bottom: ${({ isActive, theme }) =>
    isActive ? `4px solid ${theme.colors.text} !important` : "4px solid transparent"};

  ${({ theme }) => theme.mediaQueries.md} {
    flex-grow: 0;
  }

  ${color}
`;

Tab.defaultProps = {
  scale: "md",
};

export default Tab;
