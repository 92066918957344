import { ArrowLeftIcon, Box, IconButton, Flex, Text, NextLinkFromReactRouter } from '@dione/uikit'
import styled from 'styled-components'
import { ProposalTimeEnhanced } from 'hooks/contracts/governance/types'
import { ProposalMetadata } from 'utils/ipfs/types'
import { isCoreProposal } from '../../helpers'
import TimeFrame from './TimeFrame'
import { CProposalStateTag, ProposalTypeTag } from './tags'

interface ProposalRowProps {
  proposal: ProposalTimeEnhanced
  ipfs: ProposalMetadata
}

const StyledProposalRow = styled(NextLinkFromReactRouter)`
  align-items: center;
  display: flex;
  padding: 8px 16px;
  position: relative;
  background-color: transparent;
  transition: background-color 0.3s ease;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 12px 48px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.backgroundAlt3};
  }
`

const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt2};
  border-radius: 500px;
  box-shadow: ${({ theme }) => theme.shadows.card};
`

const ProposalRow: React.FC<React.PropsWithChildren<ProposalRowProps>> = ({ proposal, ipfs }) => {
  const votingLink = `/governance/proposal/${proposal.id}`

  return (
    <StyledProposalRow to={votingLink}>
      <Box as="span" style={{ flex: 1 }}>
        <Text fontWeight="500" fontSize="14px" lineHeight="20px" mb="8px">
          {ipfs.title}
        </Text>
        <Flex alignItems="center" mb="12px">
          <TimeFrame
            startDate={proposal.startTimestamp}
            endDate={proposal.expirationTimestamp}
            proposalState={proposal.state}
          />
        </Flex>
        <Flex alignItems="center">
          <CProposalStateTag proposalState={proposal.state} />
          <ProposalTypeTag isCoreProposal={isCoreProposal(proposal)} ml="8px" />
        </Flex>
      </Box>
      <StyledIconButton variant="text">
        <ArrowLeftIcon width="26px" />
      </StyledIconButton>
    </StyledProposalRow>
  )
}

export default ProposalRow
