import { ProposalState as CProposalState, ProposalTimeEnhanced, ProposalType } from 'hooks/contracts/governance/types'
import { ProposalState } from 'state/types'
import { ProposalMetadata } from 'utils/ipfs/types'
import { ADMINS } from './config'

export const isCoreProposal = (proposal: ProposalTimeEnhanced) => {
  return ADMINS.includes(proposal?.creator?.toLowerCase() || 'NOT_FOUND')
}
export const filterProposalsByType = (
  proposals: ProposalTimeEnhanced[],
  ipfsMetadatas: ProposalMetadata[],
  proposalType: ProposalType,
): [ProposalTimeEnhanced[], ProposalMetadata[]] => {
  if (proposals) {
    switch (proposalType) {
      case ProposalType.COMMUNITY: {
        const filteredRes = proposals
          .map((proposal, i) => [proposal, i])
          .filter(([proposal]) => !isCoreProposal(proposal as ProposalTimeEnhanced))

        return [
          filteredRes.map((p) => p[0] as ProposalTimeEnhanced),
          filteredRes.map((p) => ipfsMetadatas[p[1] as number] as ProposalMetadata),
        ]
      }
      case ProposalType.CORE: {
        const filteredRes = proposals
          .map((proposal, i) => [proposal, i])
          .filter(([proposal]) => isCoreProposal(proposal as ProposalTimeEnhanced))

        return [
          filteredRes.map((p) => p[0] as ProposalTimeEnhanced),
          filteredRes.map((p) => ipfsMetadatas[p[1] as number] as ProposalMetadata),
        ]
      }
      case ProposalType.ALL:
      default:
        return [proposals, ipfsMetadatas]
    }
  } else {
    return [[], []]
  }
}

export const filterProposalsByState = (
  proposals: ProposalTimeEnhanced[],
  ipfsMetadatas: ProposalMetadata[],
  state: ProposalState,
): [ProposalTimeEnhanced[], ProposalMetadata[]] => {
  const filteredRes = proposals
    .map((proposal, i) => [proposal, i])
    .filter((p) => {
      const proposal: ProposalTimeEnhanced = p[0] as ProposalTimeEnhanced

      if (state === ProposalState.CLOSED) {
        return (
          proposal.state === CProposalState.Canceled ||
          proposal.state === CProposalState.Failed ||
          proposal.state === CProposalState.Succeeded ||
          proposal.state === CProposalState.Executed ||
          proposal.state === CProposalState.Expired ||
          proposal.state === CProposalState.Queued
        )
      }
      if (state === ProposalState.ACTIVE) {
        return proposal.state === CProposalState.Active
      }
      return proposal.state === CProposalState.Pending
    })

  return [
    filteredRes.map((p) => p[0] as ProposalTimeEnhanced),
    filteredRes.map((p) => ipfsMetadatas[p[1] as number] as ProposalMetadata),
  ]
}
