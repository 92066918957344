import { Text } from '@dione/uikit'
import { toDate, format } from 'date-fns'
import { useTranslation } from '@dione/localization'
import { ProposalState } from 'state/types'
import { ProposalState as CProposalState } from 'hooks/contracts/governance/types'

interface TimeFrameProps {
  startDate: number | bigint
  endDate: number | bigint
  proposalState: ProposalState | CProposalState
}

const getFormattedDate = (timestamp: number) => {
  const date = toDate(timestamp * 1000)
  return format(date, 'MMM do, yyyy HH:mm')
}

const TimeFrame: React.FC<React.PropsWithChildren<TimeFrameProps>> = ({ startDate, endDate, proposalState }) => {
  const { t } = useTranslation()
  const textProps = {
    fontSize: '12px',
    color: 'textSubtle',
    fontWeight: '500',
    lineHeight: '18px',
  }

  if (proposalState === ProposalState.PENDING || proposalState === CProposalState.Pending) {
    return <Text {...textProps}>{t('Starts %date%', { date: getFormattedDate(Number(startDate)) })}</Text>
  }

  if (proposalState === ProposalState.ACTIVE || proposalState === CProposalState.Active) {
    return <Text {...textProps}>{t('Ends %date%', { date: getFormattedDate(Number(endDate)) })}</Text>
  }

  if (proposalState === ProposalState.CLOSED) {
    return <Text {...textProps}>{t('Ended %date%', { date: getFormattedDate(Number(endDate)) })}</Text>
  }

  return (
    <Text {...textProps}>
      {t('%state% on %date%', { state: proposalState, date: getFormattedDate(Number(endDate)) })}
    </Text>
  )
}

export default TimeFrame
